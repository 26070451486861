


































/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { debounce } from "@/helpers/debounce";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataTax } from "@/models/interface/settings.interface";
import { settingsServices } from "@/services/settings.service";
import { Component, Vue } from "vue-property-decorator";

const TaxCodeProps = Vue.extend({
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    taxType: {
      type: String,
      required: true,
    },
  },
});

@Component
export default class TaxCodeSelect extends TaxCodeProps {
  taxCodeOption = {
    data: [] as DataTax[],
    search: "",
    fetching: true,
    initialData: [] as DataTax[],
  };

  created() {
    this.getTaxCodeList(true);
  }

  async getTaxCodeList(firstLoad = false) {
    try {
      this.taxCodeOption.fetching = true;
      const param: RequestQueryParamsModel = {
        limit: 20,
        page: 0,
        search: `taxType~${this.taxType}`,
      };
      if (this.taxCodeOption.search) {
        param.search = `taxType~${this.taxType}_AND_code~*${this.taxCodeOption.search}*_OR_description~*${this.taxCodeOption.search}*_OR_taxAccount.description~*${this.taxCodeOption.search}*_OR_taxAccount.code~*${this.taxCodeOption.search}*`;
      }
      const res = await settingsServices.listOfTax(param, "");
      this.taxCodeOption.data = res.data;
      if (firstLoad) {
        this.taxCodeOption.initialData = res.data;
      }
      this.taxCodeOption.fetching = false;
    } catch (error) {
      this.taxCodeOption.fetching = false;
    }
  }

  searchTaxCode(value: string): void {
    debounce(() => {
      this.taxCodeOption.search = value;
      this.getTaxCodeList();
    });
  }

  handleChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  onMeta(value: DataTax): void {
    this.$emit("meta", value);
  }

  resetOptionList(): void {
    this.taxCodeOption.data = this.taxCodeOption.initialData;
  }

  /**
   * Fetch tax list from API until matching with @this value
   */
  async fetchMoreTaxCode() {
    try {
      this.taxCodeOption.fetching = true;
      let totalPage = 1;
      const param = {
        limit: 20,
        page: 1,
        search: `taxType~${this.taxType}`,
      };
      while (
        !this.taxCodeOption.data.find(c => c.id === this.value) &&
        param.page <= totalPage
      ) {
        const taxCodeOptions = await settingsServices.listOfTax(param, "");
        totalPage = Math.ceil(taxCodeOptions.totalElements / 20);
        this.taxCodeOption.data = this.taxCodeOption.data.concat(
          taxCodeOptions.data
        );
        param.page++;
      }
      this.taxCodeOption.fetching = false;
    } catch (error) {
      this.taxCodeOption.fetching = false;
    }
  }

  clear(): void {
    this.$emit("input", undefined);
    this.$emit("change", undefined);
    this.$emit("meta", undefined);
  }
}
