var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    _vm._b(
      {
        attrs: {
          showSearch: true,
          filterOption: false,
          allowClear: true,
          loading: _vm.taxCodeOption.fetching,
          disabled: _vm.disabled,
          value: _vm.value
        },
        on: {
          search: _vm.searchTaxCode,
          input: _vm.handleChange,
          change: _vm.handleChange
        }
      },
      "a-select",
      _vm.$attrs,
      false
    ),
    [
      _c("a-icon", {
        attrs: { slot: "clearIcon", type: "close-circle", theme: "filled" },
        on: { click: _vm.clear },
        slot: "clearIcon"
      }),
      _vm._l(_vm.taxCodeOption.data, function(item, index) {
        return _c(
          "a-select-option",
          {
            key: index,
            attrs: { value: item.id },
            on: {
              click: function($event) {
                return _vm.onMeta(item)
              }
            }
          },
          [
            _c(
              "a-tooltip",
              [
                _c("template", { slot: "title" }, [
                  _vm._v(" " + _vm._s(item.code) + " ")
                ]),
                _vm._v(" " + _vm._s(item.code) + " ")
              ],
              2
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }